import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Radius from "../assets/image/sidenav/radius.png"
import HeaderLogo from '../assets/image/webx-games-logo.png'
import MobileLogo from '../assets/image/webx-games-logo-2.png'


function Navbarone({ isOpen, isLoggedin }) {

    return (
        <>
            <Navbar key="xl" expand="xl" className={`top-new-navbar-banner ${isOpen ? "side-open-padleft" : "side-close-padleft"}`}>
                <Container fluid className='position-relative'>
                    <Navbar.Brand className='desktop-hide'>
                        <Link to="/" className="logo-text">
                            <LazyLoadImage src={MobileLogo} alt='MobileLogo' />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Brand className='mobile-hide'>
                        <Link to="/" className="logo-image-desk-1">
                            <LazyLoadImage src={HeaderLogo} alt='HeaderLogo' />
                        </Link>
                    </Navbar.Brand>

                    <Nav className="justify-content-end pe-lg-3 pe-1 gap-lg-2 gap-2 d-flex flex-row">
                        <div className='d-flex nav-item-login-signup-wrap gap-1'>
                            <button className="nav-link-signup" data-bs-toggle="modal" data-bs-target="#registerModal">
                                Register
                            </button>
                        </div>

                    </Nav>
                    <LazyLoadImage src={Radius} alt='Radius' className='nav-curve-radius' />
                </Container>
            </Navbar>
        </>
    );
}

export default Navbarone;