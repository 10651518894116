
import { RiHome5Line } from "react-icons/ri";
import { TbListSearch } from "react-icons/tb";
import { VscAccount } from "react-icons/vsc";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { BsGiftFill } from "react-icons/bs";
import { GiCard10Spades } from "react-icons/gi";
import { VscThreeBars } from "react-icons/vsc";


function Bottomnav({ isOpen, setIsOpen, isOpenmessage, setIsOpenmessage, isLoggedin, setIsLoggedin }) {

    const toggleClick = (event) => {
        event.preventDefault();
        setIsOpenmessage(false);
        setIsOpen(!isOpen);
    };
    const toggleClick2 = (event) => {
        event.preventDefault();
        setIsOpen(false);
        setIsOpenmessage(!isOpenmessage);
    };
    return (
        <>
            <div className={`top-bottom-nav-banner`} >

                <div className="d-flex justify-content-between">
                    <a href="javascript:void(0)" onClick={toggleClick}>
                        <div className="text-center">
                            <VscThreeBars className={`bootom-icon-1 ${isOpen ? "fc-y" : ""} `} />
                            <p className="mb-0">More</p>
                        </div>
                    </a>
                    <a href="">
                        <div className="text-center">
                            <RiHome5Line className="bootom-icon-1" />
                            <p className="mb-0">Home</p>
                        </div>
                    </a>
                    <a>
                        <div className="text-center">
                            <GiCard10Spades className="bootom-icon-1" />
                            <p className="mb-0">Casino </p>
                        </div>
                    </a>



                    <a>
                        <div className="text-center">
                            <VscAccount className="bootom-icon-1" />
                            <p className="mb-0">Accounts </p>
                        </div>
                    </a>

                    <a onClick={toggleClick2}>
                        <div className="text-center">
                            <IoChatbubbleEllipsesOutline className="bootom-icon-1" />
                            <p className="mb-0">Chat</p>
                        </div>
                    </a>

                </div >
            </div >
        </>
    );
}

export default Bottomnav;
