import Footertable from "./innerpages/Footer-table";
import FooterLinks from "./innerpages/Footer-Links";
import Footercurrency from "./innerpages/Footer-currency";
import Logo from '../../assets/image/footer/Logo.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Footer() {


    return (
        <>
            <div className="top-overall-footer-section">
                <Footertable />
                <FooterLinks />
                <Footercurrency />
                <section className="new-cs1-footer-section-6 my-4">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col">
                                <LazyLoadImage src={Logo} alt="Logo" />
                                <p className="footer-logo-text-1 mt-4">
                                    This website offers gaming with risk experience. 
                                    To be a user of our site you must be over 18 years old. 
                                    We are not responsible for the violation of your local 
                                    laws related to i-gaming. Play responsibly and have fun on WebX games.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
}

export default Footer;