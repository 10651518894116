import React from 'react'
import Threeslide1 from '../../../assets/image/banner/slide-sec-3/three-slide-1.png'
import Cash from '../../../assets/image/banner/Cash.png'

import { LazyLoadImage } from 'react-lazy-load-image-component';


const Footertable = () => {

    return (
        <section className="new-cs1-home-banner-section-8 my-3">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="new-cs1-home-banner-card-8">
                            <ul className="nav nav-pills " id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-high-tab" data-bs-toggle="pill" data-bs-target="#pills-high" type="button" role="tab" aria-controls="pills-high" aria-selected="false">High Wins</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-lucky-tab" data-bs-toggle="pill" data-bs-target="#pills-lucky" type="button" role="tab" aria-controls="pills-lucky" aria-selected="false">Lucky Wins</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabindex="0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Game</th>
                                                <th scope="col" className='text-center mobile-hide'>Username</th>
                                                <th scope="col" className='text-center mobile-hide'>Time</th>
                                                <th scope="col" className='text-center mobile-hide'>Wager</th>
                                                <th scope="col" className='text-center mobile-hide'>Multiplier</th>
                                                <th scope="col" className='text-end'>Payout</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(7)].map((_, index) => (

                                                <tr>
                                                    <td>
                                                        <LazyLoadImage className='banner-card-8-img-1 me-2' src={Threeslide1} alt='' />
                                                        Mission Uncrossable
                                                    </td>
                                                    <td className='text-center mobile-hide'>GangadharY</td>
                                                    <td className='text-center mobile-hide'>12:03 PM</td>
                                                    <td className='text-center mobile-hide'>
                                                        $10.45
                                                        <LazyLoadImage className='banner-card-8-img-1 ms-2' src={Cash} alt='' />

                                                    </td>
                                                    <td className='text-center mobile-hide'>
                                                        <span className='fc-g'>1.66x</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <span className='fc-g'>$17.34</span>
                                                    </td>
                                                </tr>

                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id="pills-high" role="tabpanel" aria-labelledby="pills-high-tab" tabindex="0">...</div>
                                <div className="tab-pane fade" id="pills-lucky" role="tabpanel" aria-labelledby="pills-lucky-tab" tabindex="0">...</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Footertable;