// import './App.css';
// import "./assets/css/style.css";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from './components/Navbar';
// import Sidenav from './components/Sidenav';
// import Home from './components/Homepages/Home';
// import Bottomnav from './components/Bottomnav';
// import Footer from './components/Footer/Footer';
// import React, { useState, useEffect } from 'react';
// import RegisterModal from './components/Header/RegisterModal';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';

// function App() {
//   const [isOpen, setIsOpen] = useState(true);
//   const [isLoggedin, setIsLoggedin] = useState(false);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia('(max-width: 1050px)');
//     const handleMediaQueryChange = (e) => {
//       if (e.matches) {
//         setIsOpen(false);
//       } else {
//         setIsOpen(true);
//       }
//     };
//     handleMediaQueryChange(mediaQuery);
//     mediaQuery.addEventListener('change', handleMediaQueryChange);

//     return () => {
//       mediaQuery.removeEventListener('change', handleMediaQueryChange);
//     };
//   }, []);

//   return (
//     <Router>
//       <ToastContainer />
//       <Navbar
//         isOpen={isOpen}
//         setIsOpen={setIsOpen}
//       />
//       <Sidenav
//         isOpen={isOpen}
//         setIsOpen={setIsOpen}
//       />
//       <Bottomnav
//         isOpen={isOpen}
//         setIsOpen={setIsOpen}
//       />

//       <div className={`new-cs1-overall-banner ${isOpen ? "side-open-padleft" : "side-close-padleft"}`}>
//         <Routes>
//           <Route path="/" element={<Home  />} />
//         </Routes>
//         <Footer />
//       </div>
//       <RegisterModal />
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Sidenav from './components/Sidenav';
import Home from './components/Homepages/Home';
import Bottomnav from './components/Bottomnav';
import Footer from './components/Footer/Footer';
import RegisterModal from './components/Header/RegisterModal';
import Popup from './components/Popup';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import './App.css';
import "./assets/css/style.css";

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1050px)');
    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Router>
      <ToastContainer />
      {showPopup && <Popup closePopup={closePopup} targetDate="2024-10-30T00:00:00" />}
      <Navbar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Sidenav
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Bottomnav
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className={`new-cs1-overall-banner ${isOpen ? "side-open-padleft" : "side-close-padleft"}`}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
      <RegisterModal />
    </Router>
  );
}

export default App;
