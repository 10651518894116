import React, { useState } from 'react';
import { FaCheck, FaFacebook, FaInstagram, FaTelegramPlane, FaYoutube } from 'react-icons/fa';
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowDown, MdOutlineContentCopy } from 'react-icons/md';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import countries from './countries';

const RegisterModal = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filteredCountries = countries.filter(country =>
    country.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [dropdownStates, setDropdownStates] = useState({ country: false });
  const [selectedValues, setSelectedValues] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    mobilenumber: '',
    walletaddress: '', // Default wallet address
    country: '',
  });
  const [countryCode, setCountryCode] = useState("IN");
  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    mobilenumber: '',
    walletaddress: '',
    country: '',
  });

  const toggleDropdown = (key) => {
    console.log('key :>> ', key);
    setDropdownStates(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };

  const handleSelect = (key, value) => {
    setSelectedValues(prevState => ({ ...prevState, [key]: value }));
    toggleDropdown(key);
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstname: '',
      lastname: '',
      email: '',
      city: '',
      mobilenumber: '',
      walletaddress: '',
      country: '',
    };

    if (!selectedValues.firstname.trim()) {
      errors.firstname = 'First Name is required';
      valid = false;
    }

    if (!selectedValues.lastname.trim()) {
      errors.lastname = 'Last Name is required';
      valid = false;
    }

    if (!selectedValues.email.trim() || !isValidEmail(selectedValues.email)) {
      errors.email = 'Invalid email format';
      valid = false;
    }

    if (!selectedValues.city.trim()) {
      errors.city = 'City is required';
      valid = false;
    }

    if (!selectedValues.mobilenumber.trim()) {
      errors.mobilenumber = 'Mobile number is required';
      valid = false;
    }

    if (!selectedValues.walletaddress.trim()) {
      errors.walletaddress = 'Wallet Address is required';
      valid = false;
    }

    if (!selectedValues.country.trim()) {
      errors.country = 'Country is required';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const isValidEmail = (email) => {
    // Simple email validation using regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSocialClick = (social) => {
    setSocialClickStatus(prevState => ({
      ...prevState,
      [social]: true,
    }));
  };

  const [socialClickStatus, setSocialClickStatus] = useState({
    telegram: false,
    facebook: false,
    twitter: false,
    instagram: false,
    youtube: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const allSocialClicked = Object.values(socialClickStatus).every(status => status);
        if (allSocialClicked) {
          const response = await axios.post("https://api.webxgames.com/users/user", selectedValues);
          console.log('respofasdfasdfasdfasdnse', response.data);

          if (response.data.status === true) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate('/');
            }, 2000);
          } else {
            toast.error(response.data.message);
          }

        } else {
          const notClicked = Object.keys(socialClickStatus).filter(key => !socialClickStatus[key]);
          toast.error(`Please click the following links: ${notClicked.join(', ')}`);
        }
      } catch (error) {
        toast.error('Failed to register. Please try again later.');
        console.error('Registration error:', error);
      }
    } else {
      toast.error('Please fill out all required fields correctly.');
    }
  };

  const handleCopy = (value) => {
    copy(value);
    toast.success("Copied");
  };

  return (
    <div className="register-modal">
      <div className="modal fade p-0" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-block m-0 p-0">
              <div className="deposit-modal-header d-flex justify-content-between align-items-center w-100 p-4 pt-2 pb-0">
                <p>Register</p>
                <div className='close-btn ms-auto'>
                  <button id="closeModalBtn" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
              </div>
            </div>
            <div className="modal-body w-100">
              <div className="details">
                <div className="details-head">
                  <p>Your Details</p>
                  <span>Please fill out all fields below</span>
                </div>
                <div className="details-body mt-3">
                  <div className="modal-body-form">
                    <form onSubmit={handleSubmit} autoComplete='off'>
                      <div className="name">
                        <div className='row'>
                          <div className='col-md-6 col-lg-6 col-12 mb-3'>
                            <label htmlFor="exampleInputText" className="form-label">First Name</label>
                            <input
                              type="text"
                              autoFocus
                              className="form-control"
                              value={selectedValues.firstname}
                              onChange={(e) => setSelectedValues({ ...selectedValues, firstname: e.target.value })}
                            />
                            {formErrors.firstname && <p className="alert-msg">{formErrors.firstname}</p>}
                          </div>
                          <div className='col-md-6 col-lg-6 col-12 mb-3'>
                            <label htmlFor="exampleInputText1" className="form-label">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedValues.lastname}
                              onChange={(e) => setSelectedValues({ ...selectedValues, lastname: e.target.value })}
                            />
                            {formErrors.lastname && <p className="alert-msg">{formErrors.lastname}</p>}
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-12 mb-3'>
                          <div className="email">
                            <label htmlFor="exampleInputText1" className="form-label">Email</label>
                            <input
                              type='email'
                              className="form-control"
                              value={selectedValues.email}
                              onChange={(e) => setSelectedValues({ ...selectedValues, email: e.target.value })}
                            />
                            {formErrors.email && <p className="alert-msg">{formErrors.email}</p>}
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-12'>
                          <div className="country">
                            <label htmlFor="exampleInputText2" className="form-label">Country*</label>
                            <div className="dropdown mb-2 login-country-drop-overrall">
                              <button
                                className="login-country-drop w-100"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded={dropdownStates.country}
                                onClick={() => toggleDropdown('country')}
                              >
                                <div className='d-flex flex-row align-items-center'>
                                  <div className='text-start'>
                                    <p className='login-country-text-1 mb-1'>{selectedValues.country || 'Select a country'}</p>
                                  </div>
                                  <span className='ms-auto'>
                                    <MdKeyboardArrowDown className={`dropdown-arrow ${dropdownStates.country ? 'rotate' : ''}`} />
                                  </span>
                                </div>
                              </button>
                              <ul className={`dropdown-menu p-0 ${dropdownStates.country ? 'show' : ''}`}>
                                <div className='login-country-drop-list'>
                                  <li className='p-1'>
                                    <span className="input-group-text  input-group-dropdown"><FiSearch /></span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search"
                                      autoComplete='off'
                                      value={searchQuery}
                                      onChange={e => setSearchQuery(e.target.value)}
                                    />
                                  </li>
                                  {filteredCountries.map(country => (
                                    <li
                                      key={country.Code}
                                      className={`dropdown-item ${selectedValues.country === country.Name ? 'active' : ''} d-flex justify-content-between align-items-center w-100`}
                                      onClick={() => {
                                        setSelectedValues({ ...selectedValues, country: country.Name });
                                        setCountryCode(country.Code);
                                        toggleDropdown('country');

                                      }}
                                    >
                                      {country.Name}
                                      {selectedValues.country === country.Name && <FaCheck color='#EEAF0E' className="me-2" />}
                                    </li>
                                  ))}
                                </div>
                              </ul>
                              {formErrors.country && <p className="alert-msg">{formErrors.country}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-6 col-lg-6 col-12'>
                          <label htmlFor="exampleInputText5" className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            value={selectedValues.city}
                            onChange={(e) => setSelectedValues({ ...selectedValues, city: e.target.value })}
                          />
                          {formErrors.city && <p className="alert-msg">{formErrors.city}</p>}
                        </div>
                        <div className='col-md-6 col-lg-6 col-12'>
                          <div className="mobilenumber">
                            <label htmlFor="exampleInputText4" className="form-label">Mobile Number *</label>
                            <PhoneInput
                              international
                              defaultCountry={countryCode}
                              value={selectedValues.mobilenumber}
                              onChange={(value) => setSelectedValues({ ...selectedValues, mobilenumber: value })}
                            />
                            {formErrors.mobilenumber && <p className="alert-msg">{formErrors.mobilenumber}</p>}
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-6 col-lg-6 col-12'>
                          <div className="address">
                            <label htmlFor="exampleInputText3" className="form-label" style={{ color: "#23ff3e" }}>Receiving Wallet Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={selectedValues.walletaddress}
                              onChange={(e) => setSelectedValues({ ...selectedValues, walletaddress: e.target.value })}
                            />
                            {formErrors.walletaddress && <p className="alert-msg">{formErrors.walletaddress}</p>}
                          </div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-12'>
                          <div className="address">
                            <label htmlFor="exampleInputText4" className="form-label">GTC TOKEN ADDRESS</label>
                            <div className="input-group input-group-dropdown mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={"0x2Abd93F80154280c194E048Be15902a60d609874"}
                                aria-label="Wallet Address"
                                aria-describedby="button-addon2"
                                readOnly
                              />
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                id="button-addon2"
                                onClick={() => handleCopy("0x2Abd93F80154280c194E048Be15902a60d609874")}
                              >
                                <MdOutlineContentCopy />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='form-foot'>
                        <div className='mb-2'>
                          <h6>Join Us!</h6>
                          <div className='social-links'>
                            <a href='https://t.me/gtron_official' target='_blank' rel="noreferrer"><FaTelegramPlane className={`s-icons ${socialClickStatus.telegram ? 'active' : ''}`} onClick={() => handleSocialClick('telegram')} /></a>
                            <a href='https://www.facebook.com/gtronofficial' target='_blank' rel="noreferrer"><FaFacebook className={`s-icons ${socialClickStatus.facebook ? 'active' : ''}`} onClick={() => handleSocialClick('facebook')} /></a>
                            <a href='https://x.com/gtron_official?s=20' target='_blank' rel="noreferrer"><FaXTwitter className={`s-icons ${socialClickStatus.twitter ? 'active' : ''}`} onClick={() => handleSocialClick('twitter')} /></a>
                            <a href='https://www.instagram.com/gtron_official/?igshid=NjIwNzIyMDk2Mg%3D%3D' target='_blank' rel="noreferrer"><FaInstagram className={`s-icons ${socialClickStatus.instagram ? 'active' : ''}`} onClick={() => handleSocialClick('instagram')} /></a>
                            <a href='https://www.youtube.com/@Gtron_official' target='_blank' rel="noreferrer"><FaYoutube className={`s-icons ${socialClickStatus.youtube ? 'active' : ''}`} onClick={() => handleSocialClick('youtube')} /></a>
                          </div>
                        </div>
                        <button type='submit' className='save-btn'>Register</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
