import Sec2image from "../../assets/image/banner/sec-2.png"
import Sectionfour from "./innerpages/Sectionfour"
import Sectionfive from "./innerpages/Sectionfive"
import Sectionsix from "./innerpages/Sectionsix"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PiWatchFill } from "react-icons/pi";
import { FaTrophy } from "react-icons/fa";
import Image1 from "../../assets/image/banner/sec-3/img-1.png"
import Image2 from "../../assets/image/banner/sec-3/img-2.png"
import Banner1 from "../../assets/image/banner/banner-1.png"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Countdown from "./innerpages/Countdown"

function Home({ isLoggedin }) {

    const targetDate = '2024-10-30T00:00:00';

    return (
        <>
            <div className='new-cs1-home-banner pt-5'>

                {!isLoggedin && (
                    <section className='new-cs1-home-banner-section-1'>
                        <div className="container">
                            <div className='new-cs1-home-banner-card-1'>
                                <div className="row align-items-center ">
                                    <div className="col-lg-7 text-center">
                                        <div className='new-cs1-home-banner-card-1-111'>
                                            <LazyLoadImage src={Banner1} className="new-cs1-home-banner-card-1-111-img" alt="Google" />
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className='new-cs1-home-banner-card-1-1'>
                                            <h1 className="mb-1">Welcome To WebX Games</h1>
                                            <p className="new-cs1-home-text-1">Hop In</p>
                                            <div className="mb-4">
                                                <button className="new-cs1-home-button-1" data-bs-toggle="modal" data-bs-target="#registerModal">Register Now</button>
                                            </div>
                                            <div className="login-signup__line-wrap mobile-hide-1">
                                                <div className="login-signup__line"></div>
                                                <span className="login-signup__or">Countdown Starts</span>
                                                <div className="login-signup__line"></div>
                                            </div>
                                            <div>
                                                <Countdown targetDate={targetDate} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <section className='new-cs1-home-banner-section-2 mt-5'>
                    <div className="container">
                        <div className="new-cs1-home-banner-card-2-top">
                            <div className='new-cs1-home-banner-card-2'>
                                <div className="row align-items-center g-4 flex-lg-row-reverse">
                                    <div className="col-lg-6 d-flex flex-lg-row flex-column align-items-lg-center align-items-end justify-content-center gap-3">
                                        <div className="d-flex flex-row align-items-center gap-2 p-2 new-cs1-home-banner-card-2-1 new-cs1-home-text-4">
                                            <FaTrophy style={{ color: "#FFCE00" }} />
                                            <span>100 </span>
                                            <span style={{ color: "#B4A6E2" }}>Raffle Winners</span>
                                        </div>
                                        <LazyLoadImage src={Sec2image} alt="" className="img-fluid" />
                                    </div>
                                    <div className="col-lg-6">
                                        <h3 className="new-cs1-home-text-2 mb-3">The <span className="fc-y">$130,000</span> Big BTC HODL </h3>
                                        <div className="d-flex flex-row align-items-center gap-2 new-cs1-home-banner-card-2-1">
                                            <button className="new-cs1-home-button-1 w-auto py-2">Learn More</button>
                                            <span><PiWatchFill className="fc-y" style={{ transform: "rotate(-40deg)" }} /></span>
                                            <p className="mb-0 new-cs1-home-text-3 pe-3">15d 15h 56m</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='new-cs1-home-banner-section-3 py-4'>
                    <div className="container">
                        <div className='new-cs1-home-banner-card-3 '>
                            <div className="row align-items-center g-4">
                                <div className="col-lg-6">
                                    <div className="card new-cs1-home-banner-card-3-1">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <h3 className="new-cs1-home-text-2 mb-1">Casino</h3>
                                                <span className="new-cs1-home-text-4">Thousands of Games</span>
                                            </div>
                                            <LazyLoadImage className="new-cs1-home-sec-3-img" src={Image1} alt="Casino" />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card new-cs1-home-banner-card-3-2">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <h3 className="new-cs1-home-text-2 mb-1">Sports Betting</h3>
                                                <span className="new-cs1-home-text-4">Support Your Team</span>
                                            </div>
                                            <LazyLoadImage className="new-cs1-home-sec-3-img" src={Image2} alt="Sports" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Sectionfour />
                <Sectionfive />
                <Sectionsix />
            </div>
        </>
    );
}

export default Home;