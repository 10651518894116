import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Fourslide1 from '../../../assets/image/banner/slide-sec-4/four-slide-1.png'
import Fourslide2 from '../../../assets/image/banner/slide-sec-4/four-slide-2.png'
import Fourslide3 from '../../../assets/image/banner/slide-sec-4/four-slide-3.png'
import Fourslide4 from '../../../assets/image/banner/slide-sec-4/four-slide-4.png'
import Fourslide5 from '../../../assets/image/banner/slide-sec-4/four-slide-5.png'
import { FaPlay } from "react-icons/fa";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const SectionFive = () => {

    return (
        <section className="new-cs1-home-banner-section-4">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="new-cs1-home-banner-card-4">
                            <div className="d-flex flex-lg-row flex-column justify-content-between align-items-lg-center flex-wrap mb-3">
                                <h4 className='new-cs1-home-text-5'>Lorem Originals</h4>
                                <div className="d-flex align-items-center ms-auto mobile-hide">
                                    <button className="tab-head-btn me-2">view all</button>
                                    <button className="tab-head-btn-1 image-swiper-button-prev-2"><FaChevronLeft /></button>
                                    <button className="tab-head-btn-2 image-swiper-button-next-2"><FaChevronRight /></button>
                                </div>
                            </div>
                            <div className="top-game-tab-body">
                                <Swiper
                                    slidesPerView={5}
                                    spaceBetween={10}
                                    breakpoints={{
                                        40: {
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            slidesPerView: 5,
                                        },
                                        1024: {
                                            slidesPerView: 7,
                                        },
                                    }}
                                    modules={[Navigation]}
                                    navigation={{
                                        nextEl: ".image-swiper-button-next-2",
                                        prevEl: ".image-swiper-button-prev-2",
                                        disabledClass: "swiper-button-disabled"
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide1} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide2} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide3} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide4} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide5} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide1} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide2} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide3} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide4} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='position-relative new-cs1-hoverable-sec'>
                                            <LazyLoadImage src={Fourslide5} alt="" className="game-slider-image-1" />
                                            <div className='hovered-card-bg'></div>
                                            <div className='hovered-card-design'>
                                                <div className="d-flex align-items-center flex-column h-100">
                                                    <div className="my-auto">
                                                        <span className='game-play-button'><FaPlay /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className='new-cs1-home-text-6'>WebX Games</p>
                                    </SwiperSlide>



                                </Swiper>
                            </div>
                            <div className="top-game-tab-head-right d-flex justify-content-end align-items-center gap-2 ms-auto mt-3 desktop-hide">
                                <div className="d-flex align-items-center ms-auto">
                                    <button className="tab-head-btn me-2">view all</button>
                                    <button className="tab-head-btn-1 image-swiper-button-prev-2"><FaChevronLeft /></button>
                                    <button className="tab-head-btn-2 image-swiper-button-next-2"><FaChevronRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default SectionFive;