import { Link } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import Navbar from 'react-bootstrap/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Casino from "../assets/image/sidenav/Casino.png"
import { PiArrowLineLeftBold, PiArrowLineRightBold } from "react-icons/pi";
import KoalabetGames from "../assets/image/sidenav/Casino/Koalabet-Games.png"
import Popular from "../assets/image/sidenav/Casino/Popular.png"
import Slots from "../assets/image/sidenav/Casino/Slots.png"
import Bonus from "../assets/image/sidenav/Casino/Bonus.png"
import Live from "../assets/image/sidenav/Casino/Live.png"
import Game from "../assets/image/sidenav/Casino/Game.png"
import Roulette from "../assets/image/sidenav/Casino/Roulette.png"
import Blackjack from "../assets/image/sidenav/Casino/Blackjack.png"
import Baccarat from "../assets/image/sidenav/Casino/Baccarat.png"
import SportsBet from "../assets/image/sidenav/sports/Sports-Bet.png"
import Group from "../assets/image/sidenav/promotions/Group.png"
import Group1 from "../assets/image/sidenav/promotions/Group-1.png"
import Group2 from "../assets/image/sidenav/promotions/Group-2.png"
import Group3 from "../assets/image/sidenav/promotions/Group-3.png"
import Laung from "../assets/image/sidenav/Laung.png"
import VIP from "../assets/image/sidenav/VIP.png"
import Support from "../assets/image/sidenav/Support.png"


function Sidenav({ isOpen, setIsOpen }) {

    const toggleClick = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
            <div className={`top-left-side-nav-banner ${isOpen ? "side-open-width" : "side-close-width"}`} >
                <div className={`top-left-side-nav-banner-1 ${isOpen ? "side-open-width" : "side-close-width"}`}>
                    <Navbar.Brand className='d-flex align-items-center gap-2'>
                        <button className='logo-toggle-button mobile-hide' onClick={toggleClick}>

                            {!isOpen ? (
                                <PiArrowLineRightBold className="display-slide-anim-1" />
                            ) : (
                                <PiArrowLineLeftBold className="display-slide-anim-1" />
                            )
                            }
                        </button>
                    </Navbar.Brand>
                </div>
                <div className="top-left-side-nav-banner-2">
                    <div className="light-bg">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                    <Link to="/" className="text-d-n">
                                        <button
                                            className={`accordion-button collapsed ${isOpen ? "" : "no-arrow"}`}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne"
                                            aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseOne"
                                        >
                                            <div className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-1"
                                                    alt="Sports"
                                                    src={Casino}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Casino</span>}
                                            </div>
                                        </button>
                                    </Link>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="KoalabetGames"
                                                    src={KoalabetGames}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Koalabet Games</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Popular"
                                                    src={Popular}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Popular</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Slots"
                                                    src={Slots}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Slots</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Bonus Buys"
                                                    src={Bonus}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Bonus Buys</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Live Casino"
                                                    src={Live}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Live Casino</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Game Shows"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Game Shows</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Roulette"
                                                    src={Roulette}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Roulette</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Blackjack"
                                                    src={Blackjack}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Blackjack</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Baccarat"
                                                    src={Baccarat}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Baccarat</span>}
                                            </Link>
                                        </li>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button className={`accordion-button collapsed ${isOpen ? "" : "no-arrow"}`} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        <div className="d-flex align-items-center">
                                            <LazyLoadImage
                                                className="side-accord-img-1"
                                                alt="Sports"
                                                src={SportsBet}
                                            />
                                            {isOpen && <span className="display-slide-anim-1">Sports Bet</span>}
                                        </div>
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Soccer"
                                                    src={Game}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Soccer</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Basketball"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Basketball</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Tennis"
                                                    src={Game}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Tennis</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Counter-Strike"
                                                    src={Game}
                                                />
                                                {isOpen &&
                                                    <div className="display-slide-anim-1">
                                                        <span>Counter-Strike</span>
                                                    </div>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="FIFA"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">FIFA</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Baseball"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Baseball</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Ice-Hockey"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Ice-Hockey</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Cricket"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Cricket</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="MMA"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">MMA</span>}
                                            </Link>
                                        </li>
                                        <li style={{ listStyle: "none" }} >
                                            <Link to="" className="d-flex align-items-center">
                                                <LazyLoadImage
                                                    className="side-accord-img-2"
                                                    alt="Table Tennis"
                                                    src={Game}
                                                />
                                                {isOpen && <span className="display-slide-anim-1">Table Tennis</span>}
                                            </Link>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="top-left-side-nav-banner-2-1">
                            <li style={{ listStyle: "none" }} >
                                <Link to="/" className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-3"
                                        alt="Promotions"
                                        src={Group}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>Promotions</span>
                                        </div>}
                                </Link>
                            </li>
                            <li style={{ listStyle: "none" }} >
                                <Link className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-3"
                                        alt="Refer & Earn"
                                        src={Group1}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>Refer & Earn</span>
                                        </div>}
                                </Link>
                            </li>
                            <li style={{ listStyle: "none" }} >
                                <Link to="" className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-3"
                                        alt="Redeem"
                                        src={Group2}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>Redeem</span>
                                        </div>}
                                </Link>
                            </li>
                            <li style={{ listStyle: "none" }} >
                                <Link to="" className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-3"
                                        alt="Free Play"
                                        src={Group3}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>Free Play</span>
                                        </div>}
                                </Link>
                            </li>
                        </div>

                        <div className="top-left-side-nav-banner-2-2">
                            <li style={{ listStyle: "none" }} >
                                <Link to="/" className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-1"
                                        alt="VIP"
                                        src={VIP}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>VIP Club</span>
                                        </div>}
                                </Link>
                            </li>
                        </div>
                        <div className="top-left-side-nav-banner-2-2">
                            <li style={{ listStyle: "none" }} >
                                <Link to="/" className="d-flex align-items-center">
                                    <LazyLoadImage
                                        className="side-accord-img-1"
                                        alt="Support"
                                        src={Support}
                                    />
                                    {isOpen &&
                                        <div className="display-slide-anim-1">
                                            <span>Live Support</span>
                                        </div>}
                                </Link>
                            </li>
                        </div>

                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className={`accordion-button collapsed ${isOpen ? "" : "no-arrow"}`} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        <LazyLoadImage
                                            className="side-accord-img-1"
                                            alt="English"
                                            src={Laung}
                                        />
                                        {isOpen && <span className="display-slide-anim-1">English</span>}
                                    </button>
                                </h2>
                                {isOpen &&
                                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                        <div className="accordion-body">
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>English</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>Español</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>Français</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>Srpski</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>Türkçe</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>عربي</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>Čeština</span>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li style={{ listStyle: "none" }} >
                                                <Link to="" className="d-flex align-items-center">
                                                    <div className="display-slide-anim-1">
                                                        <span>हिंदी</span>
                                                    </div>
                                                </Link>
                                            </li>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {isOpen &&
                            <div className="top-left-side-nav-banner-3-1 display-slide-anim-1">
                                <div className="">
                                    <p className="side-nav-banner-3-text-1 mb-0">Total Bets Placed</p>
                                    <p className="side-nav-banner-3-text-2 mb-0">4,571,739,189</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidenav;
