import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Fiveslide1 from '../../../assets/image/banner/slide-sec-5/five-slide-1.png'
import Fiveslide2 from '../../../assets/image/banner/slide-sec-5/five-slide-2.png'
import Fiveslide3 from '../../../assets/image/banner/slide-sec-5/five-slide-3.png'
import Fiveslide4 from '../../../assets/image/banner/slide-sec-5/five-slide-4.png'
import Fiveslide5 from '../../../assets/image/banner/slide-sec-5/five-slide-5.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Sectionsix = () => {

    return (
        <section className="new-cs1-home-banner-section-4 my-3">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="new-cs1-home-banner-card-4">
                            <div className="d-flex flex-lg-row flex-column justify-content-between align-items-lg-center flex-wrap mb-3">
                                <h4 className='new-cs1-home-text-5'>Sportsbook</h4>
                                <div className="d-flex align-items-center ms-auto mobile-hide">
                                    <button className="tab-head-btn me-2">view all</button>
                                    <button className="tab-head-btn-1 image-swiper-button-prev-3"><FaChevronLeft /></button>
                                    <button className="tab-head-btn-2 image-swiper-button-next-3"><FaChevronRight /></button>
                                </div>
                            </div>
                            <div className="top-game-tab-body">
                                <Swiper
                                    slidesPerView={5}
                                    spaceBetween={10}
                                    breakpoints={{
                                        40: {
                                            slidesPerView: 1.5,
                                            spaceBetween:10,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                        },
                                        1024: {
                                            slidesPerView: 4.8,
                                        },
                                    }}
                                    modules={[Navigation]}
                                    navigation={{
                                        nextEl: ".image-swiper-button-next-3",
                                        prevEl: ".image-swiper-button-prev-3",
                                        disabledClass: "swiper-button-disabled"
                                    }}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide1} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Soccer</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide2} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Basketball</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide3} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Tennis</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide4} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Counter-Strike</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide5} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>FIFA</p>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide1} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Soccer</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='new-cs1-home-banner-card-5'>
                                            <LazyLoadImage src={Fiveslide2} alt="" />
                                            <p className='mb-0 w-100 new-cs1-home-text-6 text-center'>Basketball</p>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>
                            <div className="top-game-tab-head-right d-flex justify-content-end align-items-center gap-2 ms-auto mt-3 desktop-hide">
                                <div className="d-flex align-items-center ms-auto">
                                    <button className="tab-head-btn me-2">view all</button>
                                    <button className="tab-head-btn-1 image-swiper-button-prev-3"><FaChevronLeft /></button>
                                    <button className="tab-head-btn-2 image-swiper-button-next-3"><FaChevronRight /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Sectionsix;