import React from 'react';
import Countdown from './Homepages/innerpages/Countdown';
import { IoClose } from 'react-icons/io5';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import img from '../assets/image/img.png'
 
const Popup = ({ closePopup, targetDate }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button onClick={closePopup} className="close-button"><IoClose /></button>
                <div className="countdown-container">
                    <LazyLoadImage src={img} alt='img' />
                    <Countdown targetDate={targetDate} />
                </div>
            </div>
        </div>
    );
};

export default Popup;
