import React, { useState, useEffect } from 'react';
import { RiTwitterXFill } from "react-icons/ri";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { FaTwitch, FaTelegramPlane, FaSpotify } from "react-icons/fa";

function FooterLinks() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section className="new-cs1-footer-section-2 my-3">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-6 row-cols-md-1 g-4">
                    <div className="col">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                {isMobile ? (
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fooOne" aria-expanded="true" aria-controls="fooOne">
                                            <p className="footer-text-1 mb-0">Casino</p>
                                        </button>
                                    </h2>
                                ) : (
                                    <p className='footer-text-1 mb-2'>Casino</p>
                                )
                                }

                                <div id="fooOne" className={`accordion-collapse collapse ${isMobile ? "" : "show"} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Lorem Originals</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Popular Games</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Slots</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Bonus Buys</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Live Casino</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Game Shows</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Roulette</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Blackjack</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Baccarat</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Fair</a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                {isMobile ? (
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fooTwo" aria-expanded="true" aria-controls="fooTwo">
                                            <p className="footer-text-1 mb-0">Sportsbook</p>
                                        </button>
                                    </h2>
                                ) : (
                                    <p className='footer-text-1 mb-2'>Sportsbook</p>
                                )
                                }

                                <div id="fooTwo" className={`accordion-collapse collapse ${isMobile ? "" : "show"} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Home</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Soccer</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Basketball</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Tennis</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Counter-Strike</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">FIFA</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Baseball</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Ice-Hockey</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Cricket</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">MMA</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Table Tennis</a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                {isMobile ? (
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fooThree" aria-expanded="true" aria-controls="fooThree">
                                            <p className="footer-text-1 mb-0">Policies</p>
                                        </button>
                                    </h2>
                                ) : (
                                    <p className='footer-text-1 mb-2'>Policies</p>
                                )
                                }

                                <div id="fooThree" className={`accordion-collapse collapse ${isMobile ? "" : "show"} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Terms of Service</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Privacy Policy</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Bonus & Promotion</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Sportsbook</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Game Policy</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">KYC-AML-CFT Policy</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Responsible Gambling</a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                {isMobile ? (
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fooFour" aria-expanded="true" aria-controls="fooFour">
                                            <p className="footer-text-1 mb-0">Promos</p>
                                        </button>
                                    </h2>
                                ) : (
                                    <p className='footer-text-1 mb-2'>Promos</p>
                                )
                                }

                                <div id="fooFour" className={`accordion-collapse collapse ${isMobile ? "" : "show"} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">VIP Club</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Promotions</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Redeem a Promo</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Affiliate Program</a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                {isMobile ? (
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#fooFive" aria-expanded="true" aria-controls="fooFive">
                                            <p className="footer-text-1 mb-0">Support</p>
                                        </button>
                                    </h2>
                                ) : (
                                    <p className='footer-text-1 mb-2'>Support</p>
                                )
                                }

                                <div id="fooFive" className={`accordion-collapse collapse ${isMobile ? "" : "show"} `} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">About Us</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Live Support</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Help Center</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Roobet Official Mirrors</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Roo Responsibility</a>
                                        </li>
                                        <li style={{ listStyle: "none" }}>
                                            <a href="">Vulnerability Disclosure</a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col">

                        <p className='footer-text-1 mb-2'>Community</p>
                        <div className='d-flex flex-lg-column gap-md-0 gap-lg-0 gap-3 flex-row'>
                            <div className='d-flex flex-row gap-3'>
                                <a href='' className='footer-link-icon-1'><RiTwitterXFill fontSize={25} /></a>
                                <a href='' className='footer-link-icon-1'><FaFacebook fontSize={25} /></a>
                                <a href='' className='footer-link-icon-1'><FaInstagram fontSize={25} /></a>
                            </div>
                            <div className='d-flex flex-row mt-lg-3 gap-3'>
                                <a href='' className='footer-link-icon-1'><FaTwitch fontSize={25} /></a>
                                <a href='' className='footer-link-icon-1'><FaTelegramPlane fontSize={25} /></a>
                                <a href='' className='footer-link-icon-1'><FaSpotify fontSize={25} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterLinks;
