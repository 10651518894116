import React from 'react'
import BTC from '../../../assets/image/footer/footer-coin/BTC.png'
import WTC from '../../../assets/image/footer/footer-coin/WTC.png'
import GTC from '../../../assets/image/footer/footer-coin/GTC.png'
import USDT from '../../../assets/image/footer/footer-coin/USDT.png'
import ETH from '../../../assets/image/footer/footer-coin/ETH.png'
import XRP from '../../../assets/image/footer/footer-coin/XRP.png'
import TRX from '../../../assets/image/footer/footer-coin/TRX.png'
import LTC from '../../../assets/image/footer/footer-coin/LTC.png'
import Doge from '../../../assets/image/footer/footer-coin/Doge.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CiGlobe } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa6";


const Footercurrency = () => {

    return (
        <section className="new-cs1-footer-section-3 my-4">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className='d-flex flex-lg-row flex-column gap-5'>
                            <div className=''>
                                <p className='footer-text-1 mb-3'>Accepted Currencies</p>
                                <div className='d-flex flex-row gap-3 flex-wrap'>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={GTC} alt='GTC' />
                                        Gtron (GTC)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={WTC} alt='WTC' />
                                        WebX Coin (WEBX)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={BTC} alt='BTC' />
                                        Bitcoin (BTC)
                                    </div>                                    
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={USDT} alt='USDT' />
                                        USD Tether (USDT)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={ETH} alt='ETH' />
                                        Ethereum (ETH)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={XRP} alt='XRP' />
                                        XRP (XRP)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={TRX} alt='TRX' />
                                        TRON (TRX)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={LTC} alt='LTC' />
                                        Litecoin (LTC)
                                    </div>
                                    <div className='d-flex gap-2 new-cs1-footer-section-3-card-1'>
                                        <LazyLoadImage src={Doge} alt='Doge' />
                                        Dogecoin (DOGE)
                                    </div>
                                </div>
                            </div>
                            <div className='ms-lg-auto '>
                                <p className='footer-text-1 mb-3'>Language</p>
                                <div className="dropdown">
                                    <button className="new-cs1-footer-section-3-card-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <CiGlobe fontSize={18}/>
                                        <span >English</span>
                                        <FaAngleDown className='ms-auto laun-sel-icon' />
                                    </button>
                                    <ul className="dropdown-menu">
                                        <div className='d-flex flex-column'>
                                            <a href="#">English</a>
                                            <a href="#">English</a>
                                            <a href="#">English</a>
                                        </div>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Footercurrency;